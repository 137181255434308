export const SubsegmentIcon = (props) => (
  <svg
    aria-hidden="true"
    fill="none"
    focusable="false"
    role="presentation"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2.0}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M14 9l6 6-6 6"
    />
    <path
      d="M4 4v7a4 4 0 0 0 4 4h11"
    />
  </svg>
);