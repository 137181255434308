export enum ImportTaskType {
  BIGQUERY = "BigQuery",
  EXAMPLE_BIG_QUERY = "ExampleBigQuery",
}

export interface ImportTaskConfig {
  id: number;
  task_type: ImportTaskType;
  project_id: string | null;
}

