import { Status } from "../models/Segmentation";
import { BaseChannelCallback, BaseChannel, ChannelResponse } from "./WebSocketService";

// Example implementation for Segmentations channel
export enum PersonalizationAction {
  PERSONALIZATION_UPDATED = "personalization_updated"
}

export interface PersonalizationMessage {
  personalization_id: number;
  value: string;
  busy: boolean;
  action: PersonalizationAction;
} 

export interface PersonalizationChannelCallback extends BaseChannelCallback {
  onPersonalizationUpdated?(data: PersonalizationMessage): void;
}

export class PersonalizationChannel extends BaseChannel<PersonalizationMessage, PersonalizationChannelCallback> {
  protected readonly CHANNEL_NAME = "personalizations";

  protected onReceived(response: ChannelResponse<PersonalizationMessage>, callback: PersonalizationChannelCallback): void {
    if (response.type !== 'message') return;

    switch (response.data.action) {
      case PersonalizationAction.PERSONALIZATION_UPDATED:
        return callback.onPersonalizationUpdated?.(response.data);
    }
  }
}