import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import BaseViewModel from './BaseViewModel';
import BigQueryCredential from '../models/BigQueryCredential';
import { ImportTaskConfig, ImportTaskType } from '../models/ImportTaskConfig';
import { ImportTask } from '../models/ImportTask';
export enum ConfigurationType {
  PERSONALIZATION_PROMPT = "personalization_prompt"
}

export interface TenantConfiguration {
  id?: number;
  tenant_id: number;
  config_type: ConfigurationType;
  config_value: {
    value: string;
  };
  removed: boolean;
}

export interface TenantWithConfigurations {
  id: number;
  name: string;
  configurations: TenantConfiguration[];
  bigquery_credentials: BigQueryCredential | null;
  import_task_config: ImportTaskConfig;
  import_tasks: ImportTask[];
}

export interface TenantUpdate {
  id: number;
  configurations: {
    config_type: ConfigurationType;
    config_value: {
      value: string;
    };
    removed: boolean;
  }[];
}

export default class TenantViewModel extends BaseViewModel {
  tenant: TenantWithConfigurations | null = null;
  availableConfigurations: ConfigurationType[] = [ConfigurationType.PERSONALIZATION_PROMPT];
  tenantId: number | null = null;

  constructor(tenantId: number) {
    super();
    makeObservable(this, {
      createBigQueryCredential: action,
      createConfiguration: action,
      createImportTaskConfiguration: action,
      deleteBigQueryCredential: action,
      deleteConfiguration: action,
      deleteImportTaskConfiguration: action,
      fetchTenant: action,
      runImportTask: action,
      updateConfiguration: action,
      updateImportTaskConfiguration: action,
      sorted_import_tasks: computed,
      availableConfigurations: observable,
      tenant: observable
    });
    this.tenantId = tenantId;
    this.fetchTenant();
  }

  get sorted_import_tasks() {
    return this.tenant?.import_tasks.slice().sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
  }

  async fetchTenant() {
    try {
      const response = await this.api.get<TenantWithConfigurations>(`/admin/tenants/${this.tenantId}`)
      runInAction(() => {
        this.tenant = response.data;
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to fetch tenants';
        this.isLoading = false;
      });
    }
  }

  async fetchBigQueryCredential() {
    try {
      await this.api.get<BigQueryCredential>(`/admin/tenants/${this.tenantId}/bigquery-credential`);
      this.fetchTenant();
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to fetch big query credential';
      });
    }
  }

  async createImportTaskConfiguration(taskType: ImportTaskType, projectId: string) {
    try {
      await this.api.post<ImportTaskConfig>(`/admin/tenants/${this.tenantId}/import-task-config`, { task_type: taskType, project_id: projectId });
      this.fetchTenant();
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to create import task configuration';
      });
    }
  }

  async updateImportTaskConfiguration(id: string, taskType: ImportTaskType, projectId: string) {
    try {
      await this.api.put<ImportTaskConfig>(`/admin/tenants/${this.tenantId}/import-task-config`, { task_type: taskType, project_id: projectId });
      this.fetchTenant();
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to update import task configuration';
      });
    }
  }

  async createBigQueryCredential(credentialsFile: File) {
    try {
      const fileContent = await credentialsFile.text();
      const credentials = JSON.parse(fileContent);

      await this.api.post<BigQueryCredential>(`/admin/tenants/${this.tenantId}/bigquery-credentials`, credentials);
      this.fetchTenant();
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to create big query credential';
      });
      throw error;
    }
  }

  async deleteImportTaskConfiguration() {
    try {
      await this.api.delete(`/admin/tenants/${this.tenantId}/import-task-config`);
      this.fetchTenant();
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to delete import task configuration';
      });
    }
  }

  async deleteBigQueryCredential() {
    try {
      await this.api.delete(`/admin/tenants/${this.tenantId}/bigquery-credentials`);
      this.fetchTenant();
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to delete big query credential';
      });
    }
  }

  async createConfiguration(configuration: {
    config_type: ConfigurationType;
    config_value: { value: string };
  }) {
    try {
      const response = await this.api.post<TenantConfiguration>(
        `/admin/tenants/${this.tenantId}/configurations`,
        configuration
      );
      runInAction(() => {
        if (this.tenant) {
          this.tenant.configurations.push(response.data);
        }
      });
      return response.data;
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to create configuration';
      });
      throw error;
    }
  }

  async updateConfiguration(configId: number, configuration: {
    config_type?: ConfigurationType;
    config_value?: { value: string };
  }) {
    try {
      const response = await this.api.put<TenantConfiguration>(
        `/admin/tenants/${this.tenantId}/configurations/${configId}`,
        configuration
      );
      runInAction(() => {
        if (this.tenant) {
          const index = this.tenant.configurations.findIndex(c => c.id === configId);
          if (index !== -1) {
            this.tenant.configurations[index] = response.data;
          }
        }
      });
      return response.data;
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to update configuration';
      });
      throw error;
    }
  }

  async deleteConfiguration(configId: number) {
    try {
      await this.api.delete(
        `/admin/tenants/${this.tenantId}/configurations/${configId}`
      );
      runInAction(() => {
        if (this.tenant) {
          this.tenant.configurations = this.tenant.configurations.filter(
            c => c.id !== configId
          );
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to delete configuration';
      });
      throw error;
    }
  }

  async runImportTask() {
    try {
      await this.api.post<ImportTask>(`/admin/tenants/${this.tenantId}/import-task`);
      this.fetchTenant();
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to run import task';
      });
      throw error;
    }
  }
}
