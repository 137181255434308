import { useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Spinner
} from "@nextui-org/react";

import RecipientDetailViewModel from '../viewModels/RecipientDetailViewModel';

const attributeColumns = [
  {
    key: "name",
    label: "Attribute Name",
  },
  {
    key: "value",
    label: "Value",
  }
];

const RecipientDetailPage = observer(() => {
  const { id } = useParams<{ id: string }>();
  const [viewModel] = useState(() => new RecipientDetailViewModel());

  useEffect(() => {
    if (id) {
      viewModel.fetchRecipientDetail(id);
    }
  }, [id, viewModel]);

  const { recipient, attributeValues, isLoading, error } = viewModel;

  const topContent = useMemo(() => {
    if (!recipient) return null;
    return (
      <div className="flex flex-col gap-4">
        <div className="flex gap-3">
          <div className="flex flex-col">
            <p className="text-xl font-bold">{recipient.first_name} {recipient.last_name}</p>
            <p className="text-small text-default-500">ID: {recipient.id}</p>
          </div>
        </div>
      </div>
    )
  }, [recipient]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <Spinner size="lg" />
      </div>
    );
  }

  if (viewModel.error) {
    return (
      <div className="flex flex-col items-center gap-4">
        <p className="text-danger">{viewModel.error}</p>
      </div>
    );
  }

  if (!recipient) {
    return (
      <div className="flex flex-col items-center gap-4">
        <p>Recipient not found</p>
      </div>
    );
  }

  return (
    <div className='flex-1 p-8 max-w-[800px] mx-auto'>
      <div className='flex flex-col h-full'>
        <Table
          aria-label="Recipient attribute values"
          className="h-full"
          isHeaderSticky
          layout="fixed"
          topContent={topContent}
          topContentPlacement="outside"
        >
          <TableHeader columns={attributeColumns}>
            {(column) => (
              <TableColumn key={column.key}>{column.label}</TableColumn>
            )}
          </TableHeader>
          <TableBody items={attributeValues}>
            {(item) => (
              <TableRow key={item.id}>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.value}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
});

export default RecipientDetailPage;
