import { SegmentSplitStatus } from "../models/Segment";
import { BaseChannelCallback, BaseChannel, ChannelResponse } from "./WebSocketService";

// Example implementation for Segmentations channel
export enum SegmentAction {
  SEGMENT_UPDATED = "segment_updated"
}

export interface SegmentMessage {
  action: SegmentAction;
  segment_id: number;
  status: SegmentSplitStatus;
}

export interface SegmentChannelCallback extends BaseChannelCallback {
  onSegmentUpdated?(data: SegmentMessage): void;
}

export class SegmentChannel extends BaseChannel<SegmentMessage, SegmentChannelCallback> {
  protected readonly CHANNEL_NAME = "segments";

  protected onReceived(response: ChannelResponse<SegmentMessage>, callback: SegmentChannelCallback): void {
    if (response.type !== 'message') return;

    switch (response.data.action) {
      case SegmentAction.SEGMENT_UPDATED:
        return callback.onSegmentUpdated?.(response.data);
    }
  }
}