import WebSocketManager from './WebSocketManager';

export interface BaseChannelCallback {
  onConnected?(): void;
  onDisconnected?(): void;
  onRejected?(): void;
}

export type ChannelResponse<T> = {
  channel: string;
} & (
  | { type: 'subscription_confirmed' | 'unsubscribed' }
  | { type: 'message'; data: T }
);

export abstract class BaseChannel<T, C extends BaseChannelCallback> {
  protected abstract readonly CHANNEL_NAME: string;
  protected wsManager: WebSocketManager;
  protected subscriptionCallback: C | null = null;

  constructor() {
    this.wsManager = WebSocketManager.getInstance();
    this.handleMessage = this.handleMessage.bind(this);
  }

  protected async setupWebSocket(): Promise<void> {
    await this.wsManager.connect();
    this.wsManager.addMessageHandler(this.CHANNEL_NAME, this.handleMessage);
    
    if (this.subscriptionCallback) {
      this.sendSubscription();
      this.onConnected(this.subscriptionCallback);
    }
  }

  protected handleMessage(event: MessageEvent): void {
    try {
      const response: ChannelResponse<T> = JSON.parse(event.data);
      if (this.subscriptionCallback) {
        this.onReceived(response, this.subscriptionCallback);
      }
    } catch (error) {
      console.error('Error processing WebSocket message:', error);
    }
  }

  protected abstract onReceived(response: ChannelResponse<T>, callback: C): void;

  protected onConnected(callback: C) {
    console.log(`Connected to ${this.CHANNEL_NAME}`);
    callback.onConnected?.();
  }

  protected onDisconnected(callback: C) {
    console.log(`Disconnected from ${this.CHANNEL_NAME}`);
    callback.onDisconnected?.();
  }

  protected onRejected(callback: C) {
    console.log(`Subscription to ${this.CHANNEL_NAME} was rejected`);
    callback.onRejected?.();
  }

  public async subscribe(callback: C): Promise<void> {
    if (this.subscriptionCallback) {
      console.warn(`Already subscribed to ${this.CHANNEL_NAME}, unsubscribing previous subscription`);
      this.unsubscribe();
    }

    this.subscriptionCallback = callback;

    if (!this.wsManager.isConnected()) {
      await this.setupWebSocket();
    } else {
      this.wsManager.addMessageHandler(this.CHANNEL_NAME, this.handleMessage);
      this.sendSubscription();
      this.onConnected(callback);
    }
  }

  protected sendSubscription(): void {
    this.wsManager.sendMessage({
      command: "subscribe",
      channel: this.CHANNEL_NAME
    });
  }

  public unsubscribe(): void {
    if (this.wsManager.isConnected()) {
      this.wsManager.sendMessage({
        command: "unsubscribe",
        channel: this.CHANNEL_NAME
      });
    }
    this.wsManager.removeMessageHandler(this.CHANNEL_NAME, this.handleMessage);
    this.subscriptionCallback = null;
  }

  public disconnect(): void {
    this.unsubscribe();
  }

  protected sendMessage(action: string, payload: any = {}): { success: boolean } {
    return {
      success: this.wsManager.sendMessage({
        command: "message",
        channel: this.CHANNEL_NAME,
        action,
        payload
      })
    };
  }
}