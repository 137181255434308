import { useCallback, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Button, Input, Spinner, Tabs, Tab } from "@nextui-org/react";
import BigQueryConfigsViewModel from "../viewModels/BigQueryConfigsViewModel";

interface BigQueryConfigsProps {
  tenantId: number;
  projectId: string;
}

interface Dataset {
  dataset_id: string;
  tables: Array<{
    table_id: string;
  }>;
}

interface DatasetsTabProps {
  datasets: Dataset[];
  onDatasetClick: (datasetId: string) => void;
  selectedDataset: string | null;
}

interface TablesTabProps {
  tables: Array<{ table_id: string }>;
  selectedTable: string;
  onTableClick: (tableId: string) => void;
  viewModel: BigQueryConfigsViewModel;
}

const DatasetsTab = ({
  datasets,
  onDatasetClick,
  selectedDataset,
}: DatasetsTabProps) => (
  <div className="mt-4">
    <h4 className="text-lg font-semibold">Available Datasets</h4>
    <ul className="list-none pl-4">
      {datasets.map((dataset) => (
        <li
          key={dataset.dataset_id}
          className={`cursor-pointer p-2 hover:bg-default-100 rounded ${
            selectedDataset === dataset.dataset_id ? "bg-default-200" : ""
          }`}
          onClick={() => onDatasetClick(dataset.dataset_id)}
        >
          {dataset.dataset_id} ({dataset.tables.length} tables)
        </li>
      ))}
    </ul>
  </div>
);

const TablesTab = ({
  tables,
  selectedTable,
  onTableClick,
  viewModel,
}: TablesTabProps) => (
  <div className="mt-4">
    <h4 className="text-lg font-semibold">Tables</h4>
    <ul className="list-none pl-4">
      {tables.map((table) => (
        <li
          key={table.table_id}
          className={`cursor-pointer p-2 hover:bg-default-100 rounded ${
            selectedTable === table.table_id ? "bg-default-200" : ""
          }`}
          onClick={() => onTableClick(table.table_id)}
        >
          {table.table_id}
          {selectedTable === table.table_id && viewModel.isLoading && (
            <Spinner size="sm" className="ml-2" />
          )}
        </li>
      ))}
    </ul>
  </div>
);

const BigQueryConfigs = observer(({ tenantId, projectId }: BigQueryConfigsProps) => {
  const [viewModel] = useState(() =>
    BigQueryConfigsViewModel.getInstance(tenantId)
  );
  const [selectedDataset, setSelectedDataset] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<string>("datasets");

  useEffect(() => {
    if (viewModel.configs.project_id) {
      handleGetInfo();
    }
  }, []);

  const handleGetInfo = useCallback(async () => {
    if (!projectId) return;

    try {
      viewModel.setError(null);
      viewModel.setLoading(true);

      const data = await viewModel.fetchDatasets(projectId);
      viewModel.setDatasets(data.datasets);
      viewModel.setTableInfo(null);
      viewModel.setSampleData(null);
      setSelectedDataset(null);
      viewModel.setConfig("dataset_name", "");
      viewModel.setConfig("table_name", "");
      setActiveTab("datasets");
    } catch (error: any) {
      viewModel.setDatasets([]);
      viewModel.setTableInfo(null);
      viewModel.setSampleData(null);
      setSelectedDataset(null);
      viewModel.setError(error.message || "Failed to fetch datasets");
    } finally {
      viewModel.setLoading(false);
    }
  }, [viewModel, projectId]);

  const handleDatasetClick = useCallback(
    async (datasetId: string) => {
      setSelectedDataset(datasetId);
      viewModel.setConfig("dataset_name", datasetId);
      viewModel.setConfig("table_name", "");
      viewModel.setTableInfo(null);
      viewModel.setSampleData(null);
      setActiveTab("tables");
    },
    [viewModel]
  );

  const handleTableClick = useCallback(
    async (tableId: string) => {
      try {
        viewModel.setError(null);
        viewModel.setLoading(true);
        viewModel.setConfig("table_name", tableId);

        const [tableInfoData, sampleData] = await Promise.all([
          viewModel.fetchTableInfo(
            projectId,
            viewModel.configs.dataset_name,
            tableId
          ),
          viewModel.fetchSampleData(
            projectId,
            viewModel.configs.dataset_name,
            tableId
          ),
        ]);

        viewModel.setTableInfo(tableInfoData);
        viewModel.setSampleData(sampleData);
        setActiveTab("info");
      } catch (error: any) {
        viewModel.setError(error.message || "Failed to fetch table data");
        viewModel.setTableInfo(null);
        viewModel.setSampleData(null);
      } finally {
        viewModel.setLoading(false);
      }
    },
    [viewModel, projectId]
  );

  if (!viewModel) {
    return (
      <div className="flex justify-center items-center h-40">
        <Spinner size="lg" />
      </div>
    );
  }

  return (
    <div className="mt-3">
      <h3 className="text-2xl font-bold">BigQuery Dataset Information</h3>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <Button
            color="primary"
            onClick={handleGetInfo}
            isDisabled={!projectId || viewModel.isLoading}
            className="w-fit"
          >
            {viewModel.isLoading ? (
              <Spinner size="sm" color="white" />
            ) : (
              "Get Dataset Information"
            )}
          </Button>
          {viewModel.error && (
            <div className="text-danger text-sm">{viewModel.error}</div>
          )}
        </div>

        {(viewModel.datasets.length > 0 ||
          viewModel.tableInfo ||
          viewModel.sampleData) && (
          <Tabs
            selectedKey={activeTab}
            onSelectionChange={(key) => setActiveTab(key.toString())}
          >
            <Tab key="datasets" title="Datasets">
              <DatasetsTab
                datasets={viewModel.datasets}
                onDatasetClick={handleDatasetClick}
                selectedDataset={selectedDataset}
              />
            </Tab>
            {selectedDataset && (
              <Tab key="tables" title="Tables">
                <TablesTab
                  tables={
                    viewModel.datasets.find(
                      (d) => d.dataset_id === selectedDataset
                    )?.tables || []
                  }
                  selectedTable={viewModel.configs.table_name}
                  onTableClick={handleTableClick}
                  viewModel={viewModel}
                />
              </Tab>
            )}
            {viewModel.tableInfo && (
              <Tab key="info" title="Table Info">
                <div className="mt-4">
                  <h4 className="text-lg font-semibold">Table Information</h4>
                  <pre>{JSON.stringify(viewModel.tableInfo, null, 2)}</pre>
                </div>
              </Tab>
            )}
            {viewModel.sampleData && (
              <Tab key="sample" title="Sample Data">
                <div className="mt-4">
                  <h4 className="text-lg font-semibold">Sample Data</h4>
                  <pre>{JSON.stringify(viewModel.sampleData, null, 2)}</pre>
                </div>
              </Tab>
            )}
          </Tabs>
        )}
      </div>
    </div>
  );
});

export default BigQueryConfigs;
