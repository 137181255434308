export const ScatterOptions = {
  animation: false,
  parsing: false,
  scales: {
      x: {
        min: -1.1,
        max: 1.1,
        ticks: {
          display: false,
        }
      },
      y: {
        min: -1.1,
        max: 1.1,
        title: {
          display: false,
        },
        ticks: {
          display: false,
        }
      },
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      tooltip: {
        enabled: false
      }
    }
  };