import { runInAction, makeObservable, observable, action } from 'mobx';
import BaseViewModel from './BaseViewModel';
import { NormalizationEnum } from '../constants/normalizations';
import { Attribute } from '../models/Attribute';

export default class AttributesPageViewModel extends BaseViewModel {
  attributes: Attribute[] = [];
  currentAttribute: Attribute = { name: '', description: '', normalization: NormalizationEnum.NONE, is_outcome: false };
  isLoading = false;
  error: string | null = null;

  constructor() {
    super();
    makeObservable(this, {
      attributes: observable,
      currentAttribute: observable,
      createAttribute: action,
      fetchAttributes: action,
      setCurrentAttribute: action,
      saveAttribute: action,
      deleteAttribute: action,
      resetCurrentAttribute: action,
    });
  }

  async createAttribute(attribute: Attribute) {
    try {
      await this.api.post('/attributes', attribute);
      await this.fetchAttributes();
    } catch (error) {
      // TODO: Missing implementation
    }
  }

  async fetchAttributes() {
    runInAction(() => {
      this.isLoading = true;
      this.error = null;
    });

    try {
      const response = await this.api.get<Attribute[]>('/attributes');
      runInAction(() => {
        this.attributes = response.data.sort((a, b) => a.name.localeCompare(b.name));
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to fetch attributes. Please try again later.';
        this.isLoading = false;
      });
    }
  }

  setCurrentAttribute(attribute: Attribute | null) {
    this.currentAttribute = attribute || { name: '', description: '', normalization: NormalizationEnum.NONE, is_outcome: false };
  }

  async saveAttribute(attribute: Attribute) {
    try {
      if (attribute.id) {
        await this.api.put(`/attributes/${attribute.id}`, attribute);
      } else {
        await this.api.post('/attributes', attribute);
      }
      this.setCurrentAttribute(null);
      await this.fetchAttributes();
    } catch (error) {
      // TODO: Missing implementation
    }
  }

  async deleteAttribute(id: string) {
    try {
      await this.api.delete(`/attributes/${id}`);
      await this.fetchAttributes();
    } catch (error) {
      // TODO: Missing implementation
    }
  }

  resetCurrentAttribute() {
    this.currentAttribute = { name: '', description: '', normalization: NormalizationEnum.NONE, is_outcome: false };
  }
}