// import Segmentation from './Segmentation';
// import { RecipientSegment } from './RecipientSegment';
// import { Personalization } from './Personalization';

export interface Segment {
  id: number;
  description?: string;
  expected_outcome?: number;
  name: string;
  parent_id?: number;
  status: SegmentSplitStatus;
  size: number;
  subsegments: Segment[];
  // recipientSegments: RecipientSegment[];
  // personalizations: Personalization[];
}

export enum SegmentSplitStatus {
  ERROR = 'Error',
  QUEUED = 'Queued',
  STOPPED = 'Stopped',
  GENERATING_SEGMENT_DESCRIPTIONS = 'Generating Segment Descriptions',
  GENERATING_PERSONALIZATIONS = 'Generating Personalizations',
  PREPROCESSING_DATA = 'Preprocessing Data',
  PERFORMING_CLUSTERING = 'Performing Clustering',
  PROCESS_DONE = 'Process Done',
}

export interface SegmentUpdate {
  segment_id: number;
  status: SegmentSplitStatus;
}
