import { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Button,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tooltip
} from '@nextui-org/react';
import { ColumnSize } from "@react-types/table";
import { Segment, SegmentSplitStatus } from '../models/Segment';
import { Status } from '../models/Segmentation';
import { DeleteIcon } from '../assets/icons/DeleteIcon';
import { SplitIcon } from '../assets/icons/SplitIcon';
import { SubsegmentIcon } from '../assets/icons/SubsegmentIcon';

interface SegmentsComponentProps {
  error: string | null;
  isLoading: boolean;
  onDeleteSubsegment?: (segment: Segment) => void;
  onSelectedSegmentChange: (segment: Segment) => void;
  onSplitSegment?: (segment: Segment) => void;
  segments?: Segment[];
  selectedSegmentId?: string;
  status: Status;
}

type SegmentsColumnKey = 'name' | 'size' | 'actions';

const segmentsTableColumns: { key: SegmentsColumnKey, label: string, width: ColumnSize | null }[] = [
  { key: 'name', label: 'Name', width: null },
  { key: 'size', label: 'Size', width: 80 },
  { key: 'actions', label: '', width: 70 },
];

interface FlatSegment extends Segment {
  depth: number;
}

const SegmentsComponent = observer((props: SegmentsComponentProps) => {
  const {
    error,
    isLoading,
    onDeleteSubsegment,
    onSelectedSegmentChange,
    onSplitSegment,
    segments,
    selectedSegmentId,
    status,
  } = props;

  const flattenedSegments = useMemo(() => {
    const flatten = (segments: Segment[]): Segment[] => {
      return segments.flatMap(segment => [
        segment,
        ...(segment.subsegments?.length ? flatten(segment.subsegments) : [])
      ]);
    };
    return segments ? flatten(segments) : [];
  }, [segments]);

  const getStringForSplittingStatus = (status: SegmentSplitStatus) => {
    switch (status) {
      case SegmentSplitStatus.GENERATING_SEGMENT_DESCRIPTIONS:
        return 'Generating segment descriptions';
      case SegmentSplitStatus.GENERATING_PERSONALIZATIONS:
        return 'Generating personalizations';
      case SegmentSplitStatus.PERFORMING_CLUSTERING:
        return 'Performing clustering';
      case SegmentSplitStatus.PREPROCESSING_DATA:
        return 'Preprocessing data';
      case SegmentSplitStatus.PROCESS_DONE:
        return 'Process done';
      case SegmentSplitStatus.ERROR:
        return 'Error';
      default:
        return 'Unknown status';
    }
  };

  const renderSegmentCell = useCallback((segment: Segment, columnKey: SegmentsColumnKey) => {
    switch (columnKey) {
      case 'name':
        return (
          <div className='flex flex-col'>
            <span className='text-sm flex items-center gap-1'>
              {segment.parent_id !== null && <SubsegmentIcon height={16} width={16} />}{segment.name}
            </span>
            {segment.status !== SegmentSplitStatus.PROCESS_DONE && segment.status !== SegmentSplitStatus.STOPPED && (
              <span className='text-default-400 text-xs'>{getStringForSplittingStatus(segment.status)}</span>
            )}
            {segment.subsegments?.length > 0 && (
              <span className='text-default-400 text-xs'>
                Subsegments: {segment.subsegments?.length}
              </span>
            )}
          </div>
        )
      case 'size':
        return (
          <div className='flex flex-col'>
            <span className='text-sm'>{segment.size}</span>
          </div>
        )
      case 'actions':
        return (
          <div className="flex items-center gap-2 justify-end">
            {segment.parent_id !== null && (
              <Tooltip content="Delete subsegment">
                <Button
                  isIconOnly
                  variant="light"
                  onClick={() => onDeleteSubsegment?.(segment)}
                >
                  <DeleteIcon />
                </Button>
              </Tooltip>
            )}
            {segment.parent_id === null && (
              <Tooltip content="Split segment">
                <Button
                  isIconOnly
                  variant="light"
                  onClick={() => onSplitSegment?.(segment)}
                >
                  <SplitIcon />
                </Button>
              </Tooltip>
            )}
          </div>
        )
      default:
        return "nothing"
    }
  }, [onDeleteSubsegment, onSplitSegment]);

  if (error) {
    return (
      <div className='grid place-items-center place-content-center'>
        <p className="text-danger">{error}</p>
      </div>
    );
  }

  return (
    <>
      <h1 className='text-2xl font-bold ml-2'>Segments</h1>
      {isLoading && (
        <div className='flex-1 flex justify-center items-center'>
          <Spinner />
        </div>
      )}
      {status === Status.PROCESS_DONE && !isLoading && (
        <Table
          aria-label="Segmentation control table with dynamic content"
          classNames={{ base: "overflow-auto p-2" }}
          layout='fixed'
          isHeaderSticky
          onSelectionChange={(keys) => {
            const selectedKey = Array.from(keys)[0];
            const selectedSegment = flattenedSegments?.find(seg => seg.id?.toString() === selectedKey?.toString());
            if (selectedSegment) {
              onSelectedSegmentChange(selectedSegment);
            }
          }}
          selectedKeys={[selectedSegmentId || '']}
          selectionMode="single"
          selectionBehavior="replace"
        >
          <TableHeader columns={segmentsTableColumns}>
            {(column) => <TableColumn key={column.key} width={column.width}>{column.label}</TableColumn>}
          </TableHeader>
          <TableBody items={flattenedSegments}>
            {(item) => (
              <TableRow key={item.id}>
                {(columnKey) => <TableCell>{renderSegmentCell(item, columnKey as SegmentsColumnKey)}</TableCell>}
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </>
  );
});

export default SegmentsComponent;