import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import {
  Button,
  Input,
  Select,
  SelectItem,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Textarea,
  Tooltip,
} from "@nextui-org/react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@nextui-org/react";

import TenantViewModel, {
  ConfigurationType,
} from "../../viewModels/TenantViewModel";
import { EditIcon } from "../../assets/icons/EditIcon";
import toast, { Toaster } from "react-hot-toast";
import { TenantConfiguration } from "../../viewModels/TenantViewModel";
import { DeleteIcon } from "../../assets/icons/DeleteIcon";
import { getTypedFormData } from "../../utils/TypeFormData";
import BigQueryConfigs from "../../components/BigQueryConfigs";
import { ImportTaskType } from "../../models/ImportTaskConfig";

type ColumnKey = keyof TenantConfiguration | "actions";

type Column = {
  key: ColumnKey & React.Key;
  label: string;
  width?: number | null;
};

// Type-safe columns definition
const columns: Column[] = [
  {
    key: "config_type",
    label: "Type",
    width: 200,
  },
  {
    key: "config_value",
    label: "Value",
    width: null,
  },
  {
    key: "actions",
    label: "Actions",
    width: 100,
  },
];

type FormData = {
  config_type?: string;
  config_value?: string;
};

const TenantPage = observer(() => {
  const { id } = useParams();
  const [viewModel] = useState(() => new TenantViewModel(Number(id)));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedConfiguration, setSelectedConfiguration] =
    useState<TenantConfiguration | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleSubmitConfiguration = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    if (!tenant) return;
    event.preventDefault();

    const formData = getTypedFormData<FormData>(event.currentTarget);

    const params = {
      config_type: formData.get("config_type") as ConfigurationType,
      config_value: {
        value: formData.get("config_value") || "",
      },
    };

    try {
      if (selectedConfiguration?.id) {
        await viewModel.updateConfiguration(selectedConfiguration.id, params);
      } else {
        await viewModel.createConfiguration(params);
      }
      setIsModalOpen(false);
      toast.success("Configuration updated successfully");
    } catch (error) {
      toast.error("Failed to update configuration");
    }
  };

  const handleDeleteConfiguration = async (id: number) => {
    try {
      await viewModel.deleteConfiguration(id);
      toast.success("Configuration deleted successfully");
    } catch (error) {
      toast.error("Failed to delete configuration");
    }
  };

  const handleSubmitImportTaskConfiguration = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const formData = getTypedFormData<{ config_id: number, import_task_type: ImportTaskType, project_id: string }>(event.currentTarget);
    const configId = formData.get("config_id");
    const taskType = formData.get("import_task_type");
    const projectId = formData.get("project_id");
    if (configId) {
      await viewModel.updateImportTaskConfiguration(configId.toString(), taskType as ImportTaskType, projectId);
    } else {
      await viewModel.createImportTaskConfiguration(taskType as ImportTaskType, projectId);
    }
  };

  const { tenant } = viewModel;

  const renderCell = (
    configuration: TenantConfiguration,
    columnKey: React.Key
  ) => {
    switch (columnKey) {
      case "config_type":
        return (
          <div className="flex flex-col">
            <span className="text-tiny text-default-500">
              {configuration.config_type}
            </span>
          </div>
        );
      case "config_value":
        return (
          <div className="m-w-full">
            <p className="text-sm line-clamp-2">
              {configuration.config_value.value}
            </p>
          </div>
        );
      case "actions":
        return (
          <div className="relative flex items-center gap-2">
            <Tooltip content="Edit">
              <span
                onClick={() => {
                  setSelectedConfiguration(configuration);
                  setIsModalOpen(true);
                }}
                className="text-lg text-default-400 cursor-pointer active:opacity-50"
              >
                <EditIcon />
              </span>
            </Tooltip>
            <Tooltip color="danger" content="Delete">
              <span
                onClick={() => {
                  if (configuration.id) {
                    handleDeleteConfiguration(configuration.id);
                  }
                }}
                className="text-lg text-danger cursor-pointer active:opacity-50"
              >
                <DeleteIcon />
              </span>
            </Tooltip>
          </div>
        );
      default:
        return "NA";
    }
  };

  const topContent = React.useMemo(() => {
    return (
      <Button
        className="w-fit"
        color="primary"
        onClick={() => {
          setSelectedConfiguration(null);
          setIsModalOpen(true);
        }}
      >
        Add Configuration
      </Button>
    );
  }, []);

  if (viewModel.error) {
    return (
      <div className="grid place-items-center place-content-center">
        <p className="text-danger">{viewModel.error}</p>
      </div>
    );
  }

  return (
    <>
      <Toaster />
      <Modal
        isOpen={isDeleteModalOpen}
        onOpenChange={() => setIsDeleteModalOpen(false)}
        placement="top-center"
      >
        <ModalContent>
          {(onClose) => (
            <form onSubmit={handleSubmitConfiguration}>
              <ModalHeader className="flex flex-col gap-1">
                Tenant Configuration
              </ModalHeader>
              <ModalBody>
                <p>
                  Are you sure you want to delete the BigQuery credentials? This
                  action cannot be undone.
                </p>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" variant="flat" onPress={onClose}>
                  Cancel
                </Button>
                <Button
                  color="danger"
                  variant="flat"
                  onPress={() => {
                    viewModel.deleteBigQueryCredential();
                    onClose();
                  }}
                >
                  Delete
                </Button>
              </ModalFooter>
            </form>
          )}
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isModalOpen}
        onOpenChange={() => setIsModalOpen(false)}
        placement="top-center"
      >
        <ModalContent>
          {(onClose) => (
            <form onSubmit={handleSubmitConfiguration}>
              <ModalHeader className="flex flex-col gap-1">
                Tenant Configuration
              </ModalHeader>
              <ModalBody>
                <Select
                  label="Type"
                  id="config_type"
                  name="config_type"
                  key="config_type"
                  defaultSelectedKeys={
                    selectedConfiguration?.config_type
                      ? [selectedConfiguration.config_type]
                      : []
                  }
                  isRequired
                >
                  {viewModel.availableConfigurations.map(
                    (configuration: ConfigurationType) => {
                      return (
                        <SelectItem key={configuration} value={configuration}>
                          {configuration}
                        </SelectItem>
                      );
                    }
                  )}
                </Select>
                <Textarea
                  id="config_value"
                  name="config_value"
                  key="config_value"
                  label="Value"
                  type="text"
                  defaultValue={selectedConfiguration?.config_value.value}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="flat" onPress={onClose}>
                  Close
                </Button>
                <Button color="primary" type="submit">
                  Save
                </Button>
              </ModalFooter>
            </form>
          )}
        </ModalContent>
      </Modal>
      <div className="flex-1 p-4">
        <div className="grid grid-cols-2 gap-4 h-full">
          <div className="col-span-1 flex flex-col gap-2 h-full overflow-y-scroll">
            <h1 className="text-2xl font-bold">Basic Information</h1>
            <Input
              value={tenant?.name || ""}
              disabled
              id="name"
              label="Name"
              type="text"
            />
            {tenant && (
              <div className="flex flex-col gap-2">
                <h1 className="text-2xl font-bold">Import Task Configuration</h1>
                <form onSubmit={handleSubmitImportTaskConfiguration} className="flex flex-col gap-2">
                  <Input
                    id="config_id"
                    name="config_id"
                    key="config_id"
                    type="hidden"
                    value={tenant?.import_task_config?.id.toString() || ""} />
                  <Select
                    label="Import Task Type"
                    id="import_task_type"
                    name="import_task_type"
                    key="import_task_type"
                    defaultSelectedKeys={[tenant?.import_task_config?.task_type || ""]}
                  >
                    {Object.values(ImportTaskType).map((taskType) => (
                      <SelectItem key={taskType} value={taskType}>
                        {taskType}
                      </SelectItem>
                    ))}
                  </Select>
                  <Input
                    defaultValue={tenant?.import_task_config?.project_id || ""}
                    id="project_id"
                    label="Project ID"
                    type="text"
                    name="project_id"
                    key="project_id"
                  />
                  <div className="flex gap-2">
                    <Button className="w-fit" color="primary" type="submit">
                      Save
                    </Button>
                    {tenant?.import_task_config && (
                      <Button className="w-fit" color="danger" onClick={() => {
                        viewModel.deleteImportTaskConfiguration();
                      }}>
                        Delete
                      </Button>
                    )}
                  </div>
                </form>
                <h1 className="text-2xl font-bold">Import Tasks</h1>
                <Button className="w-fit" color="primary" onClick={() => {
                  viewModel.runImportTask();
                }}>
                  Run Import Task
                </Button>
                {viewModel?.sorted_import_tasks && (
                  <Table>
                    <TableHeader>
                      <TableColumn>Status</TableColumn>
                      <TableColumn>Created At</TableColumn>
                      <TableColumn>Updated At</TableColumn>
                    </TableHeader>
                    <TableBody>
                      {viewModel.sorted_import_tasks.map((task) => (
                        <TableRow key={task.id}>
                          <TableCell>{task.status}</TableCell>
                          <TableCell>{task.created_at.toLocaleString()}</TableCell>
                          <TableCell>{task.updated_at.toLocaleString()}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </div>
            )}
            <h1 className="text-2xl font-bold">BigQuery Credential</h1>
            {tenant?.bigquery_credentials && (
              <>
                <div>
                  <Input
                    value={tenant?.bigquery_credentials?.client_email || ""}
                    disabled
                    id="client_email"
                    label="Client Email"
                    type="text"
                  />
                  <Button
                    className="w-fit"
                    color="danger"
                    variant="flat"
                    onClick={() => {
                      setIsDeleteModalOpen(true);
                    }}
                  >
                    Delete credentials
                  </Button>
                </div>
                <BigQueryConfigs tenantId={Number(id)} projectId={tenant?.import_task_config?.project_id || ""} />
              </>
            )}
            {!tenant?.bigquery_credentials && (
              <div className="flex flex-col gap-4">
                <p>No BigQuery Credential found</p>
                <div className="flex flex-col gap-2">
                  <Input
                    type="file"
                    accept="application/json"
                    onChange={(e) => {
                      const file = e.target.files?.[0];
                      if (file) {
                        setSelectedFile(file);
                      }
                    }}
                    className="max-w-xs"
                  />
                  <p className="text-tiny text-default-500">
                    Upload your BigQuery service account JSON file
                  </p>
                  <Button
                    className="w-fit"
                    color="primary"
                    isDisabled={!selectedFile}
                    onClick={() => {
                      if (selectedFile) {
                        try {
                          viewModel
                            .createBigQueryCredential(selectedFile)
                            .then(() => {
                              toast.success(
                                "BigQuery credentials created successfully"
                              );
                              setSelectedFile(null);
                            })
                            .catch(() => {
                              toast.error(
                                "Failed to create BigQuery credentials"
                              );
                            });
                        } catch (error) {
                          toast.error("Invalid JSON file");
                        }
                      }
                    }}
                  >
                    Upload Credentials
                  </Button>
                </div>
              </div>
            )}
          </div>
          <div className="col-span-1 flex flex-col gap-2">
            <h1 className="text-2xl font-bold">Configurations</h1>
            <Table
              aria-label="Example table with dynamic content"
              className="h-full"
              isHeaderSticky
              layout="fixed"
              topContent={topContent}
            >
              <TableHeader columns={columns}>
                {(column) => (
                  <TableColumn key={column.key} width={column.width}>
                    {column.label}
                  </TableColumn>
                )}
              </TableHeader>
              <TableBody items={tenant?.configurations || []}>
                {(item) => (
                  <TableRow key={item.id}>
                    {(columnKey) => (
                      <TableCell>{renderCell(item, columnKey)}</TableCell>
                    )}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
});

export default TenantPage;
