import { Status } from "../models/Segmentation";
import { BaseChannelCallback, BaseChannel, ChannelResponse } from "./WebSocketService";

// Example implementation for Segmentations channel
export enum SegmentationsAction {
  SEGMENTATION_UPDATED = "segmentation_updated"
}

export interface SegmentationMessage {
  segmentation_id: number;
  status: Status;
  action: SegmentationsAction;
}

export interface SegmentationsChannelCallback extends BaseChannelCallback {
  onSegmentationUpdated?(data: SegmentationMessage): void;
}

export class SegmentationsChannel extends BaseChannel<SegmentationMessage, SegmentationsChannelCallback> {
  protected readonly CHANNEL_NAME = "segmentations";

  protected onReceived(response: ChannelResponse<SegmentationMessage>, callback: SegmentationsChannelCallback): void {
    if (response.type !== 'message') return;

    switch (response.data.action) {
      case SegmentationsAction.SEGMENTATION_UPDATED:
        return callback.onSegmentationUpdated?.(response.data);
    }
  }
}