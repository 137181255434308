import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Select, SelectItem, Button } from '@nextui-org/react';
import toast from 'react-hot-toast';
import ExportViewModel, { ExportFormat } from '../viewModels/ExportViewModel';

interface ExportSegmentationResultModalProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  exportViewModel: ExportViewModel;
}

const exportFormats = [
  { label: 'CSV', value: 'csv' },
  // { label: 'Excel', value: 'excel' }
];

const ExportSegmentationResultModal = ({ isOpen, onOpenChange, exportViewModel }: ExportSegmentationResultModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">Export Data</ModalHeader>
            <ModalBody>
              <Select
                label="Export Format"
                defaultSelectedKeys={[exportViewModel.format]}
                onChange={(e) => exportViewModel.setFormat(e.target.value as ExportFormat)}
              >
                {exportFormats.map((format) => (
                  <SelectItem key={format.value} value={format.value}>
                    {format.label}
                  </SelectItem>
                ))}
              </Select>
              {exportViewModel.error && (
                <p className="text-danger">{exportViewModel.error}</p>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="danger" variant="flat" onPress={onClose}>
                Close
              </Button>
              <Button
                color="primary"
                onPress={() => {
                  exportViewModel.startExport().then(() => {
                    if (!exportViewModel.error) {
                      toast.success('Export completed successfully');
                      onClose();
                    } else {
                      toast.error('Failed to export data');
                    }
                  });
                }}
                isLoading={exportViewModel.isLoading}
              >
                Download
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ExportSegmentationResultModal; 