import AttributesPage from './pages/AttributesPage';
import CriteriaPage from './pages/CriteriaPage';
import DashboardPage from './pages/DashboardPage';
import TenantSettingsPage from './pages/TenantSettingsPage';
import RecipientsPage from './pages/RecipientsPage';
import RecipientDetailPage from './pages/RecipientDetailPage';
import SegmentationPage from './pages/SegmentationPage';
import SegmentationsPage from './pages/SegmentationsPage';
import VariablesPage from './pages/VariablesPage';
import UsersPage from './pages/admin/UsersPage';
import TenantsPage from './pages/admin/TenantsPage';
import TenantPage from './pages/admin/TenantPage';
import { UserRoleType } from './models/UserSystemRole';

export interface Route {
  children?: Route[];
  element?: React.ReactNode;  
  includeInNav?: boolean;
  label: string;
  path?: string;
  requiredRole?: UserRoleType;
}

export const routes: Route[] = [
  { path: '/dashboard', label: 'Dashboard', element: <DashboardPage />, includeInNav: true },
  { path: '/criteria', label: 'Criteria', element: <CriteriaPage />, includeInNav: true },
  { path: '/variables', label: 'Variables', element: <VariablesPage />, includeInNav: true },
  { path: '/attributes', label: 'Attributes', element: <AttributesPage />, includeInNav: true },
  { path: '/recipients', label: 'Recipients', element: <RecipientsPage />, includeInNav: true },
  { path: '/recipients/:id', label: 'Recipient Detail', element: <RecipientDetailPage />, includeInNav: false },
  { path: '/segmentations', label: 'Segmentations', element: <SegmentationsPage />, includeInNav: true },
  { path: '/segmentations/:id', label: 'Segmentation', element: <SegmentationPage />, includeInNav: false },
  { path: '/segmentations/:id/:segmentId', label: 'Segmentation', element: <SegmentationPage />, includeInNav: false },
  { path: '/tenant_settings', label: 'Tenant Settings', element: <TenantSettingsPage />, includeInNav: false },
  { path: '/admin/users', label: 'Users', element: <UsersPage />, includeInNav: false, requiredRole: UserRoleType.ADMIN },
  { path: '/admin/users/:id', label: 'Users', element: <UsersPage />, includeInNav: false, requiredRole: UserRoleType.ADMIN },
  { path: '/admin/tenants', label: 'Tenants', element: <TenantsPage />, includeInNav: false, requiredRole: UserRoleType.ADMIN },
  { path: '/admin/tenants/:id', label: 'Tenant', element: <TenantPage />, includeInNav: false, requiredRole: UserRoleType.ADMIN },
];
