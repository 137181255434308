import React, { useCallback, useState, Key } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Input,
  Select,
  SelectItem,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Tabs,
  Tab,
  Chip
} from '@nextui-org/react';
import { ColumnSize } from "@react-types/table";


import { VerticalDotsIcon } from '../assets/icons/VerticalDotsIcon';
import Segmentation, { TOTAL_STEPS } from '../models/Segmentation';
import { getTypedFormData } from '../utils/TypeFormData';
import SegmentationCriterion from '../models/SegmentationCriterion';
import Criterion from '../models/Criterion';
import { Status, STATUS_TO_STEP } from '../models/Segmentation';
import { Attribute } from '../models/Attribute';

type FormData = {
  id?: string;
  name?: string;
  prompt?: string;
}

type ColumnKey = 'name' | 'actions';

const TableColumns: { key: ColumnKey, label: string, width: ColumnSize | null }[] = [
  {
    key: "name",
    label: "Name",
    width: null
  },
  {
    key: "actions",
    label: "Actions",
    width: 80
  },
];

interface SegmentationCriteriaListProps {
  availableCriteria: Criterion[];
  segmentation: Segmentation;
  selectedAttributes: Attribute[];
  status: Status;
  onCriteriaDelete: (segmentationCriterion: SegmentationCriterion) => void;
  onCriteriaAdd: (id: string) => void;
  onCriterionCreate: (name: string, prompt: string) => void;
  onExport: () => void;
  onPreviewStart: () => void;
  onProcessStart: () => void;
  onStop: () => void;
}

const SegmentationCriteriaList = observer((props: SegmentationCriteriaListProps) => {
  const {
    availableCriteria,
    segmentation,
    selectedAttributes,
    status,
    onCriteriaDelete,
    onCriteriaAdd,
    onCriterionCreate,
    onExport,
    onPreviewStart,
    onProcessStart,
    onStop
  } = props;

  const [openDialog, setOpenDialog] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = getTypedFormData<FormData>(event.currentTarget);
    if (formData.get('id')) {
      onCriteriaAdd(formData.get('id') || "");
    } else {
      onCriterionCreate(formData.get('name') || "", formData.get('prompt') || "");
    }
    setOpenDialog(false);
  }

  const renderCell = useCallback((segmentationCriteria: SegmentationCriterion, columnKey: Key) => {
    switch (columnKey) {
      case "name":
        return (
          <div className="flex flex-col">
            <p>{segmentationCriteria.criterion.name}</p>
            <p className="text-default-400 line-clamp-3">{segmentationCriteria.criterion.prompt}</p>
          </div>
        );

      case "actions":
        return (
          <div className="relative flex justify-end items-center gap-2">
            <Dropdown>
              <DropdownTrigger>
                <Button isIconOnly size="sm" variant="light">
                  <VerticalDotsIcon className="text-default-300" />
                </Button>
              </DropdownTrigger>
              <DropdownMenu>
                <DropdownItem onClick={() => onCriteriaDelete(segmentationCriteria)}>Delete</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        );
      default:
        return null;
    }
  }, [onCriteriaDelete]);

  const getChipColor = (status: Status) => {
    switch (status) {
      case Status.GENERATING_SEGMENT_DESCRIPTIONS:
      case Status.GENERATING_PERSONALIZATIONS:
      case Status.PERFORMING_CLUSTERING:
      case Status.PREPROCESSING_DATA:
        return 'primary';
      case Status.PROCESS_DONE:
        return 'success';
      case Status.ERROR:
        return 'danger';
      default:
        return 'warning';
    }
  };

  const topContent = React.useMemo(() => {
    return (
      <div className="flex flex-col gap-4">
        <div className="flex gap-3">
          <Button className="bg-foreground text-background" onClick={() => setOpenDialog(true)}>
            Add New
          </Button>
          <Button color="primary" onClick={onProcessStart}>
            Process
          </Button>
          <Button color="danger" onClick={onStop}>
            Stop
          </Button>
          <Button color="secondary" onClick={onExport}>
            Export
          </Button>
        </div>
        <Chip color={getChipColor(status)} variant="dot">{status} {STATUS_TO_STEP[status]}/{TOTAL_STEPS}</Chip>
        {selectedAttributes?.length > 0 && status !== Status.PROCESS_DONE && (
          <>
            <p className="text-sm font-bold ml-3">Selected Attributes:</p>
            <div className="flex flex-wrap gap-2">
              {selectedAttributes?.map((attribute) => (
                <Chip key={attribute.id} variant="flat" color="default">
                  {attribute.name}
                </Chip>
              ))}
            </div>
          </>
        )}
      </div >
    )
  }, [onProcessStart, onExport, onStop, status, selectedAttributes])

  return (
    <>
      <Modal
        isOpen={openDialog}
        onOpenChange={() => setOpenDialog(false)}
        placement="top-center"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Add Criterion</ModalHeader>
              <ModalBody>
                <Tabs aria-label="Options">
                  <Tab key="photos" title="Select Existing">
                    <form onSubmit={handleSubmit} className="flex flex-col gap-2">
                      <Select
                        id="id"
                        name="id"
                        label="Criterion"
                      >
                        {availableCriteria.map((criterion) => (
                          <SelectItem key={criterion.id} value={criterion.id}>
                            {criterion.name}
                          </SelectItem>
                        ))}
                      </Select>
                      <div className="flex justify-end gap-2">
                        <Button type="button" onPress={onClose}>
                          Close
                        </Button>
                        <Button color="primary" type="submit">
                          Save
                        </Button>
                      </div>
                    </form>
                  </Tab>
                  <Tab key="music" title="Create New">
                    <form onSubmit={handleSubmit} className="flex flex-col gap-2">
                      <Input
                        id="name"
                        name="name"
                        label="Name"
                      />
                      <Input
                        id="prompt"
                        name="prompt"
                        label="Prompt"
                      />
                      <div className="flex justify-end gap-2">
                        <Button type="button" onPress={onClose}>
                          Close
                        </Button>
                        <Button color="primary" type="submit">
                          Save
                        </Button>
                      </div>
                    </form>
                  </Tab>
                </Tabs>
              </ModalBody>
              {/* <ModalFooter>
                <Button color="danger" variant="flat" onPress={onClose}>
                  Close
                </Button>
                <Button color="primary" onPress={() => {
                  console.log('save');
                  onClose();
                }}>
                  Save
                </Button>
              </ModalFooter> */}
            </>
          )}
        </ModalContent>
      </Modal>
      <h1 className='text-2xl font-bold ml-2'>Criteria</h1>
      <Table
        aria-label="Selection segmentation criteria table with dynamic content"
        isHeaderSticky
        classNames={{ base: "overflow-auto p-2" }}
        layout='fixed'
        selectionMode="single"
        selectionBehavior="replace"
        topContent={topContent}
        topContentPlacement="inside"
      >
        <TableHeader columns={TableColumns}>
          {(column) => <TableColumn key={column.key} width={column.width}>{column.label}</TableColumn>}
        </TableHeader>
        <TableBody items={segmentation.segmentation_criteria}>
          {(item) => (
            <TableRow
              // onDragStart={() => {
              //   console.log('drag start');
              // }}
              // onDragEnd={() => {
              //   console.log('drag end');
              // }}
              // draggable={true} 
              key={item.id}>
              {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
})

export default SegmentationCriteriaList;