import { runInAction, makeObservable, observable, action } from 'mobx';
import BaseViewModel from './BaseViewModel';
import { Recipient } from './RecipientsPageViewModel';

export interface AttributeValue {
  id: string;
  name: string;
  value: string;
  type: string;
}

export default class RecipientDetailViewModel extends BaseViewModel {
  recipient: Recipient | null = null;
  attributeValues: AttributeValue[] = [];

  constructor() {
    super();
    makeObservable(this, {
      attributeValues: observable,
      recipient: observable,
      fetchRecipientDetail: action
    });
    runInAction(() => {
      this.isLoading = true;
    });
  }

  async fetchRecipientDetail(id: string) {
    runInAction(() => {
      this.isLoading = true;
      this.error = null;
    });

    try {
      const [recipientResponse, attributeValuesResponse] = await Promise.all([
        this.api.get<Recipient>(`/recipients/${id}`),
        this.api.get<AttributeValue[]>(`/recipients/${id}/attributes`)
      ]);
      runInAction(() => {
        this.recipient = recipientResponse.data;
        this.attributeValues = attributeValuesResponse.data;
      });
    } catch (error) {
      this.error = 'Failed to fetch recipient details. Please try again later.';
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }
} 