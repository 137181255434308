import AuthStore from '../stores/AuthStore';

type MessageHandler = (event: MessageEvent) => void;

class WebSocketManager {
  private static instance: WebSocketManager;
  private ws: WebSocket | null = null;
  private messageHandlers: Map<string, Set<MessageHandler>> = new Map();
  private connectionPromise: Promise<void> | null = null;
  private connectionResolvers: Set<() => void> = new Set();

  public static getInstance(): WebSocketManager {
    if (!WebSocketManager.instance) {
      WebSocketManager.instance = new WebSocketManager();
    }
    return WebSocketManager.instance;
  }

  public async connect(): Promise<void> {
    if (this.ws?.readyState === WebSocket.OPEN) {
      return Promise.resolve();
    }

    if (this.connectionPromise) {
      return this.connectionPromise;
    }

    this.connectionPromise = new Promise((resolve) => {
      this.connectionResolvers.add(resolve);

      if (this.ws?.readyState === WebSocket.CONNECTING) {
        return;
      }

      const url = process.env.REACT_APP_WSS_URL || 'ws://localhost:4000';
      this.ws = new WebSocket(`${url}/ws?authorization=${AuthStore.token}`);

      this.ws.onopen = () => {
        console.log('WebSocket connected');
        this.connectionResolvers.forEach(resolver => resolver());
        this.connectionResolvers.clear();
        this.connectionPromise = null;
      };

      this.ws.onclose = () => {
        console.log('WebSocket disconnected');
        this.messageHandlers.clear();
        this.ws = null;
      };

      this.ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      this.ws.onmessage = (event) => {
        try {
          const response = JSON.parse(event.data);
          const channel = response.channel;
          
          const handlers = this.messageHandlers.get(channel);
          if (handlers) {
            handlers.forEach(handler => handler(event));
          }
        } catch (error) {
          console.error('Error processing WebSocket message:', error);
        }
      };
    });

    return this.connectionPromise;
  }

  public addMessageHandler(channel: string, handler: MessageHandler): void {
    if (!this.messageHandlers.has(channel)) {
      this.messageHandlers.set(channel, new Set());
    }
    this.messageHandlers.get(channel)?.add(handler);
  }

  public removeMessageHandler(channel: string, handler: MessageHandler): void {
    this.messageHandlers.get(channel)?.delete(handler);
    if (this.messageHandlers.get(channel)?.size === 0) {
      this.messageHandlers.delete(channel);
    }
  }

  public sendMessage(message: any): boolean {
    if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
      console.error('Cannot send message: WebSocket not connected');
      return false;
    }

    this.ws.send(JSON.stringify(message));
    return true;
  }

  public disconnect(): void {
    if (this.ws) {
      this.ws.close();
      this.ws = null;
    }
    this.messageHandlers.clear();
  }

  public isConnected(): boolean {
    return this.ws?.readyState === WebSocket.OPEN;
  }
}

export default WebSocketManager; 