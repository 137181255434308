import { runInAction, makeObservable, observable, action, computed } from 'mobx';
import BaseViewModel from './BaseViewModel';
import { Segment, SegmentUpdate, SegmentSplitStatus } from '../models/Segment';
import { Variable } from './VariablesPageViewModel';
import Personalization from '../models/Personalization';
import Segmentation, { Status } from '../models/Segmentation';

import { PersonalizationChannel, PersonalizationMessage } from '../services/PersonalizationChannel';
import { SegmentChannel, SegmentMessage } from '../services/SegmentChannel';
import { SegmentationMessage, SegmentationsChannel } from '../services/SegmentationsChannel';

import {
  VariableWithPersonalizations,
  VisualizationData,
  Visualization
} from '../types/segmentation';
import { Attribute } from '../models/Attribute';

export default class SegmentationResultViewModel extends BaseViewModel {
  private currentPCView: 'pc1_pc2' | 'pc2_pc3' | 'pc3_pc1' = 'pc1_pc2';
  private personalizationChannel: PersonalizationChannel = new PersonalizationChannel();
  private segmentChannel: SegmentChannel = new SegmentChannel();
  private segmentationChannel: SegmentationsChannel = new SegmentationsChannel();

  currentSegmentation?: Segmentation = undefined;
  error: string | null = null;
  isLoading = false;
  personalizations: Personalization[] = [];
  segmentationId?: number = undefined;
  segments: Segment[] = [];
  selectedAttributes: Attribute[] = [];
  selectedSegmentId?: string = undefined;
  task_id?: string = undefined;
  variables: Variable[] = [];
  visualizationData: Visualization[] | null = [];

  constructor(segmentationId: number) {
    super();
    this.segmentationId = segmentationId;
    makeObservable(this, {
      addPersonalization: action,
      clearData: action,
      createPersonalization: action,
      deletePersonalization: action,
      deleteSubsegment: action,
      fetchData: action,
      fetchSelectedAttributes: action,
      recreatePersonalizations: action,
      setCurrentSegmentation: action,
      setSegmentationId: action,
      setSelectedSegmentId: action,
      splitSegment: action,
      startProcessing: action,
      updatePersonalization: action,
      busyProcessing: computed,
      chartSeries: computed,
      currentVisualizationData: computed,
      sortedSegments: computed,
      variablesWithPersonalizations: computed,
      currentSegmentation: observable,
      personalizations: observable,
      segmentationId: observable,
      segments: observable,
      selectedSegmentId: observable, variables: observable,
      visualizationData: observable,
    });
  }

  clearData = () => {
    runInAction(() => {
      this.segments = [];
      this.variables = [];
      this.personalizations = [];
      this.visualizationData = [];
      this.selectedAttributes = [];
    });
  }

  setSegmentationId(segmentationId: number) {
    runInAction(() => {
      this.segmentationId = segmentationId;
      this.fetchData();
    });

    this.personalizationChannel.subscribe({
      onPersonalizationUpdated: (data: PersonalizationMessage) => {
        this.handlePersonalizationUpdate(data);
      }
    });
    this.segmentChannel.subscribe({
      onSegmentUpdated: (data: SegmentMessage) => {
        this.handleSegmentUpdate(data);
      }
    });
    this.segmentationChannel.subscribe({
      onSegmentationUpdated: (data: SegmentationMessage) => {
        this.handleStatusUpdate(data);
      }
    });
  }

  handlePersonalizationUpdate = (event: PersonalizationMessage) => {
    runInAction(() => {
      const personalization = this.personalizations.find(p => p.id === event.personalization_id);
      if (personalization) {
        this.personalizations = this.personalizations.map(p => p.id === event.personalization_id ? { ...p, value: event.value, busy: event.busy } : p);
      }
    });
  }

  handleSegmentUpdate = (event: SegmentMessage) => {
    runInAction(() => {
      const segment = this.segments.find(s => s.id === event.segment_id);
      if (segment) {
        this.segments = this.segments.map(s => s.id === event.segment_id ? { ...s, status: event.status } : s);
      }
    });
    if (event.status === SegmentSplitStatus.PROCESS_DONE) {
      this.fetchSegmentData();
      this.fetchPersonalizationData();
    }
  }

  createPersonalization = async (variable: VariableWithPersonalizations) => {
    if (!this.segmentationId) {
      return;
    }
    try {
      const response = await this.api.post(`/segmentations/${this.segmentationId}/personalizations/`, {
        segmentation_id: this.segmentationId,
        variable_id: variable.id,
        segment_id: Number(this.selectedSegmentId)
      });
      runInAction(() => {
        this.personalizations = [...this.personalizations, response.data];
      });
    } catch (err) {
      this.error = `Failed to create personalization ${err}`;
    }
  }

  deletePersonalization = async (personalization: Personalization) => {
    if (!this.segmentationId) {
      return;
    }
    try {
      await this.api.delete(`/segmentations/${this.segmentationId}/personalizations/${personalization.id}`);
      this.personalizations = this.personalizations.filter(p => p.id !== personalization.id);
    } catch (err) {
      this.error = `Failed to delete personalization ${err}`;
    }
  }

  updatePersonalization = async (variable: VariableWithPersonalizations, personalization: Personalization, newValue: string) => {
    if (!this.segmentationId) {
      return;
    }
    try {
      const updatedPersonalization = await this.api.put<Personalization>(`/segmentations/${this.segmentationId}/personalizations/${personalization.id}`, {
        value: newValue
      });
      this.personalizations = this.personalizations.map(p => p.id === personalization.id ? updatedPersonalization.data : p);
    } catch (err) {
      this.error = `Failed to update personalization ${err}`;
    }
  }

  duplicatePersonalization = async (variable: VariableWithPersonalizations, personalization: Personalization) => {
    if (!this.segmentationId) {
      return;
    }
    try {
      const duplicatedPersonalization = await this.api.post<Personalization>(`/segmentations/${this.segmentationId}/personalizations/${personalization.id}/duplicate`);
      this.personalizations = [...this.personalizations, duplicatedPersonalization.data];
    } catch (err) {
      this.error = `Failed to duplicate personalization ${err}`;
    }
  }

  setSelectedSegmentId(segmentId: string) {
    this.selectedSegmentId = segmentId;
  }

  get sortedSegments(): Segment[] {
    if (!this.segments) {
      return [];
    }

    // Create a deep copy of segments to avoid modifying the original array
    return this.segments.slice().sort((a, b) => a.name.localeCompare(b.name)).map(segment => {
      // If the segment has subsegments, sort them as well
      if (segment.subsegments && segment.subsegments.length > 0) {
        return {
          ...segment,
          subsegments: segment.subsegments.slice().sort((a, b) => a.name.localeCompare(b.name))
        };
      }
      return segment;
    });
  }

  get variablesWithPersonalizations(): VariableWithPersonalizations[] {
    if (!this.selectedSegmentId) {
      return [];
    }
    return this.variables.map(variable => {
      return {
        ...variable,
        personalizations: this.personalizations.filter(p => p.variable_id === variable.id && p.segment_id === Number(this.selectedSegmentId))
      };
    });
  }

  async addPersonalization(segment_id: number, variable_id: string, value: string) {
    if (!this.segmentationId) {
      return;
    }
    runInAction(() => {
      this.isLoading = true;
      this.error = null;
    });
    try {
      await this.api.put(`/personalizations`, {
        segmentation_id: this.segmentationId,
        segment_id: Number(segment_id),
        variable_id: Number(variable_id),
        value
      });
      this.fetchData();
    } catch (error) {
      // TODO: Implementation missing
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  deleteSubsegment = async (segment: Segment) => {
    if (!this.segmentationId) {
      return;
    }
    try {
      await this.api.delete(`/segments/${segment.id}`);
      runInAction(() => {
        if (segment.parent_id) {
          this.segments = this.segments.map(s => {
            if (s.id === segment.parent_id) {
              return {
                ...s,
                subsegments: s.subsegments.filter(sub => sub.id !== segment.id)
              };
            }
            return s;
          });
        }
        this.segments = this.segments.filter(s => s.id !== segment.id);
      });
    } catch (error) {
      this.error = `Failed to delete subsegment ${error}`;
    }
  }

  async fetchData() {
    if (!this.segmentationId) {
      return;
    }
    runInAction(() => {
      this.isLoading = true;
      this.error = null;
    });
    try {
      const [response, variables, personalizations, visualization, selectedAttributes] = await Promise.all([
        this.api.get<Segment[]>(`/segmentations/${this.segmentationId}/segments`),
        this.api.get<Variable[]>(`/variables`),
        this.api.get<Personalization[]>(`/segmentations/${this.segmentationId}/personalizations`),
        this.api.get<Visualization[]>(`/segmentations/${this.segmentationId}/visualization`),
        this.api.get<Attribute[]>(`/segmentations/${this.segmentationId}/selected_attributes`)
      ]);

      runInAction(() => {
        this.segments = response.data;
        this.personalizations = personalizations.data;
        this.variables = variables.data;
        this.visualizationData = visualization.data;
        this.selectedAttributes = selectedAttributes.data;
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = `Failed to fetch segments. Please try again later. ${error}`;
        this.isLoading = false;
      });
    }
  }

  async fetchSelectedAttributes() {
    if (!this.segmentationId) {
      return;
    }
    const response = await this.api.get<Attribute[]>(`/segmentations/${this.segmentationId}/selected_attributes`);
    runInAction(() => {
      this.selectedAttributes = response.data;
    });
  }

  async fetchSegmentData() {
    if (!this.segmentationId) {
      return;
    }
    const response = await this.api.get<Segment[]>(`/segmentations/${this.segmentationId}/segments`);
    runInAction(() => {
      this.segments = response.data
    });
  }

  async fetchPersonalizationData() {
    if (!this.segmentationId) {
      return;
    }
    const response = await this.api.get<Personalization[]>(`/segmentations/${this.segmentationId}/personalizations`);
    runInAction(() => {
      this.personalizations = response.data;
    })
  }

  get busyProcessing(): boolean {
    return (this.currentSegmentation?.status !== Status.PROCESS_DONE &&
      this.currentSegmentation?.status !== Status.STOPPED &&
      this.currentSegmentation?.status !== Status.DRAFT &&
      this.currentSegmentation?.status !== Status.ERROR) ||
      this.isLoading;
  }

  get currentVisualizationData(): VisualizationData[] {
    if (!this.visualizationData) {
      return [];
    }
    const data = this.visualizationData.find(v => v.pc_combination === this.currentPCView)?.cluster_data ?? [];
    return data
  }

  private hexToRgba(hex: string, opacity = 1): string {
    // Remove the hash if present
    hex = hex.replace('#', '');

    // Parse the hex values
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Return rgba format
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

  get chartSeries() {
    return this.currentVisualizationData.map(cluster => ({
      label: cluster.name,
      data: cluster.data.map(point => ({
        x: point.x,
        y: point.y
      })),
      backgroundColor: this.hexToRgba(cluster.fill)
    }));
  }

  setCurrentSegmentation = (segmentation: Segmentation) => {
    this.currentSegmentation = segmentation;
  }

  handleStatusUpdate = (data: SegmentationMessage) => {
    runInAction(() => {
      if (this.currentSegmentation && this.currentSegmentation.id === data.segmentation_id) {
        this.currentSegmentation.status = data.status;

        switch (data.status) {
          case Status.PROCESS_DONE:
            this.fetchData();
            break;
          case Status.PERFORMING_CLUSTERING:
            this.fetchSelectedAttributes();
            break;
          default:
            break;
        }
      }
    });
  }



  startProcessing = async () => {
    if (!this.currentSegmentation) {
      return;
    }
    try {
      const response = await this.api.post<{ task_id: string }>(
        `/segmentations/${this.currentSegmentation.id}/run`
      );
      this.task_id = response.data.task_id;
      this.clearData(); // Clear existing data when starting new processing
    } catch (err) {
      this.error = `Failed to start ${err}`;
    }
  }

  splitSegment = async (segment: Segment) => {
    if (!this.currentSegmentation) {
      return;
    }
    try {
      await this.api.post<{ task_id: string }>(
        `/segments/${segment.id}/run`
      );
    } catch (err) {
      this.error = `Failed to split segment ${err}`;
    }
  }

  recreatePersonalizations = async (variable: VariableWithPersonalizations, personalization: Personalization) => {
    if (!this.currentSegmentation) {
      return;
    }
    try {
      await this.api.post<{ task_id: string }>(
        `/segmentations/${this.currentSegmentation.id}/personalizations/${personalization.id}/rerun`
      );
    } catch (err) {
      this.error = `Failed to recreate personalizations ${err}`;
    }
  }

  dispose = () => {
    this.personalizationChannel.unsubscribe();
    this.segmentChannel.unsubscribe();
    this.segmentationChannel.unsubscribe();
  }
}
