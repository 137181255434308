import { makeObservable, observable, action } from "mobx";
import BaseViewModel from "./BaseViewModel";

export interface BigQueryConfigs {
  project_id: string;
  dataset_name: string;
  table_name: string;
}

interface DatasetResponse {
  data: {
    datasets: Array<{
      dataset_id: string;
      tables: Array<{ table_id: string }>;
    }>;
  };
}

interface TableInfo {
  metadata: {
    project_id: string;
    dataset_name: string;
    table_name: string;
    created?: string;
    modified?: string;
    num_bytes?: number;
    num_rows?: number;
    schema_size: number;
  };
  schema: Array<{
    column_name: string;
    data_type: string;
    is_nullable: string;
    ordinal_position: number;
  }>;
}

export default class BigQueryConfigsViewModel extends BaseViewModel {
  configs: BigQueryConfigs = {
    project_id: "",
    dataset_name: "",
    table_name: "",
  };

  datasets: Array<DatasetResponse["data"]["datasets"][0]> = [];
  tableInfo: TableInfo | null = null;
  sampleData: Record<string, any> | null = null;

  private static instances: Map<number, BigQueryConfigsViewModel> = new Map();

  private constructor(private tenantId: number) {
    super();
    makeObservable(this, {
      configs: observable,
      datasets: observable,
      tableInfo: observable,
      sampleData: observable,
      setConfig: action,
      setDatasets: action,
      setTableInfo: action,
      setSampleData: action,
      setError: action,
      setLoading: action,
      fetchDatasets: action,
      fetchTableInfo: action,
      fetchSampleData: action,
    });
  }

  static getInstance(tenantId: number): BigQueryConfigsViewModel {
    if (!this.instances.has(tenantId)) {
      this.instances.set(tenantId, new BigQueryConfigsViewModel(tenantId));
    }
    return this.instances.get(tenantId)!;
  }

  setConfig(key: keyof BigQueryConfigs, value: string) {
    this.configs[key] = value;
  }

  setDatasets(datasets: Array<DatasetResponse["data"]["datasets"][0]>) {
    this.datasets = datasets;
  }

  setTableInfo(info: TableInfo | null) {
    this.tableInfo = info;
  }

  setSampleData(data: Record<string, any> | null) {
    this.sampleData = data;
  }

  setError(error: string | null) {
    this.error = error;
  }

  setLoading(loading: boolean) {
    this.isLoading = loading;
  }

  async fetchDatasets(project_id: string): Promise<DatasetResponse["data"]> {
    try {
      const response = await this.api.get<DatasetResponse>(
        `/tenants/${this.tenantId}/bigquery/datasets`,
        {
          params: { project_id },
        }
      );
      return response.data.data;
    } catch (error: any) {
      throw new Error(
        error.response?.data?.detail || "Failed to fetch datasets"
      );
    }
  }

  async fetchTableInfo(
    project_id: string,
    dataset_name: string,
    table_name: string
  ): Promise<TableInfo> {
    try {
      const response = await this.api.get<{ data: TableInfo }>(
        `/tenants/${this.tenantId}/bigquery/table-info`,
        {
          params: { project_id, dataset_name, table_name },
        }
      );
      return response.data.data;
    } catch (error: any) {
      throw new Error(
        error.response?.data?.detail || "Failed to fetch table info"
      );
    }
  }

  async fetchSampleData(
    project_id: string,
    dataset_name: string,
    table_name: string
  ): Promise<Record<string, any>> {
    try {
      const response = await this.api.get<{ data: Record<string, any> }>(
        `/tenants/${this.tenantId}/bigquery/sample`,
        {
          params: { project_id, dataset_name, table_name },
        }
      );
      return response.data.data;
    } catch (error: any) {
      throw new Error(
        error.response?.data?.detail || "Failed to fetch sample data"
      );
    }
  }

  cleanup() {
    BigQueryConfigsViewModel.instances.delete(this.tenantId);
  }
}
