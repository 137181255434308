import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

import { Scatter } from 'react-chartjs-2';
import { Card, Spinner } from '@nextui-org/react';
import toast, { Toaster } from 'react-hot-toast';

import SegmentationConfigurationViewModel from '../viewModels/SegmentationConfigurationViewModel';
import SegmentationResultViewModel from '../viewModels/SegmentationResultViewModel';
import { VariableWithPersonalizations } from '../types/segmentation';
import { Segment } from '../models/Segment';
import ExportViewModel from '../viewModels/ExportViewModel';
import SegmentationCriterion from '../models/SegmentationCriterion';
import SegmentsComponent from '../components/SegmentsComponent';
import SegmentationCriteriaList from '../components/SegmentationCriteriaList';
import SegmentPersonalizationComponent from '../components/SegmentPersonalizationComponent';
import Personalization from '../models/Personalization';
import { ScatterOptions } from '../constants/scatterOptions';
import ExportSegmentationResultModal from '../components/ExportSegmentationResultModal';

const SegmentationPage = observer(() => {
  const { id, segmentId } = useParams();

  const navigate = useNavigate();
  const [viewModel] = useState(() => new SegmentationConfigurationViewModel());
  const [resultViewModel] = useState(() => new SegmentationResultViewModel(Number(id)));
  const [exportViewModel] = useState(() => new ExportViewModel());
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  useEffect(() => {
    if (id && !isNaN(Number(id))) {
      viewModel.fetchSegmentation(Number(id));
      resultViewModel.setSegmentationId(Number(id));
      exportViewModel.setSegmentationId(Number(id));
    }
  }, [resultViewModel, viewModel, exportViewModel, id]);

  useEffect(() => {
    if (viewModel.currentSegmentation) {
      resultViewModel.setCurrentSegmentation(viewModel.currentSegmentation);
    }
  }, [viewModel.currentSegmentation, resultViewModel]);

  useEffect(() => {
    if (segmentId) {
      resultViewModel.setSelectedSegmentId(segmentId);
    }
  }, [resultViewModel, segmentId]);

  const handleCriteriaDelete = (segmentationCriterion: SegmentationCriterion) => {
    if (segmentationCriterion) {
      viewModel.deleteCriterion(segmentationCriterion);
    }
  };

  const handleCriteriaAdd = (id: string) => {
    if (viewModel.currentSegmentation?.id) {
      const newPriority = viewModel.currentSegmentation.segmentation_criteria?.length ?? 0;
      viewModel.addCriterion(id, newPriority, viewModel.currentSegmentation.id);
    }
  };

  const handleCriterionCreate = (name: string, prompt: string) => {
    if (viewModel.currentSegmentation?.id) {
      const newPriority = viewModel.currentSegmentation.segmentation_criteria?.length ?? 0;
      viewModel.createCriterion(name, prompt, newPriority, viewModel.currentSegmentation.id);
    }
  };

  const handlePreviewStart = () => {
    //  resultViewModel.clearData();
    //  processingViewModel.startPreview();
  };

  const handleProcessStart = () => {
    resultViewModel.startProcessing();
  };

  const handleStop = () => {
    //  processingViewModel.stopProcessing();
  };

  const handleExport = () => {
    setIsExportModalOpen(true);
  };

  const handleRecreatePersonalizations = (variable: VariableWithPersonalizations, personalization: Personalization) => {
    resultViewModel.recreatePersonalizations(variable, personalization).then(() => {
      toast.success('Personalizations recreation started');
    }).catch(() => {
      toast.error('Failed to recreate personalizations');
    });
  };

  const handleUpdatePersonalization = (variable: VariableWithPersonalizations, personalization: Personalization, newValue: string) => {
    resultViewModel.updatePersonalization(variable, personalization, newValue).then(() => {
      toast.success('Personalization updated successfully');
    }).catch(() => {
      toast.error('Failed to update personalization');
    });
  };

  const handleDuplicatePersonalization = (variable: VariableWithPersonalizations, personalization: Personalization) => {
    resultViewModel.duplicatePersonalization(variable, personalization).then(() => {
      toast.success('Personalization duplicated successfully');
    }).catch(() => {
      toast.error('Failed to duplicate personalization');
    });
  };

  const handleDeletePersonalization = (personalization: Personalization) => {
    resultViewModel.deletePersonalization(personalization).then(() => {
      toast.success('Personalization deleted successfully');
    }).catch(() => {
      toast.error('Failed to delete personalization');
    });
  };

  const handleCreatePersonalization = (variable: VariableWithPersonalizations) => {
    resultViewModel.createPersonalization(variable).then(() => {
      toast.success('Personalization created successfully');
    }).catch(() => {
      toast.error('Failed to create personalization');
    });
  };

  const handleRunSplitSegment = (segment: Segment) => {
    resultViewModel.splitSegment(segment).then(() => {
      toast.success('Segment split started');
    }).catch(() => {
      toast.error('Failed to run segment split');
    });
  };

  const { availableCriteria, currentSegmentation } = viewModel;
  const { variablesWithPersonalizations, segments, sortedSegments } = resultViewModel;

  return (
    <div className='flex-1 p-4 overflow-y-auto'>
      <Toaster />
      <ExportSegmentationResultModal
        isOpen={isExportModalOpen}
        onOpenChange={setIsExportModalOpen}
        exportViewModel={exportViewModel}
      />
      <div className='grid gap-y-8'>
        <div className='row-span-1 h-[400px]'>
          <div className='grid grid-cols-3 h-[400px]'>
            <div className='col-span-1 flex flex-col h-[400px]'>
              {currentSegmentation && (
                <SegmentationCriteriaList
                  availableCriteria={availableCriteria}
                  onCriteriaAdd={handleCriteriaAdd}
                  onCriteriaDelete={handleCriteriaDelete}
                  onCriterionCreate={handleCriterionCreate}
                  onExport={handleExport}
                  onPreviewStart={handlePreviewStart}
                  onProcessStart={handleProcessStart}
                  onStop={handleStop}
                  segmentation={currentSegmentation}
                  selectedAttributes={resultViewModel.selectedAttributes}
                  status={currentSegmentation.status}
                />
              )}
            </div>
            <div className='col-span-1 flex flex-col h-[400px]'>
              {currentSegmentation && (
                <SegmentsComponent
                  onSelectedSegmentChange={(segment: Segment) => {
                    navigate(`/segmentations/${id}/${segment.id}`);
                  }}
                  onSplitSegment={handleRunSplitSegment}
                  onDeleteSubsegment={(segment: Segment) => {
                    resultViewModel.deleteSubsegment(segment).then(() => {
                      toast.success('Subsegment deleted successfully');
                    }).catch(() => {
                      toast.error('Failed to delete subsegment');
                    });
                  }}
                  segments={sortedSegments}
                  selectedSegmentId={segmentId}
                  status={currentSegmentation.status}
                  isLoading={resultViewModel.busyProcessing}
                  error={resultViewModel.error}
                />
              )}
            </div>
            <div className='col-span-1 flex flex-col h-[400px]'>
              <h1 className='text-2xl font-bold mb-2'>Cluster Visualization</h1>
              {resultViewModel.busyProcessing && (
                <div className='flex-1 flex justify-center items-center'>
                  <Spinner />
                </div>
              )}
              {!resultViewModel.busyProcessing && (
                <Card className='flex-1 p-2'>
                  <Scatter
                    height={200}
                    data={{ datasets: resultViewModel.chartSeries }}
                    options={ScatterOptions} />
                </Card>
              )}
            </div>
          </div>
        </div>
        <div className='row-span-1'>
          {segments && (
            <SegmentPersonalizationComponent
              selectedSegmentId={segmentId}
              variablesWithPersonalizations={variablesWithPersonalizations}
              isLoading={resultViewModel.busyProcessing}
              error={resultViewModel.error}
              status={currentSegmentation?.status}
              onCreatePersonalization={handleCreatePersonalization}
              onDuplicatePersonalization={handleDuplicatePersonalization}
              onUpdatePersonalization={handleUpdatePersonalization}
              onRecreatePersonalizations={handleRecreatePersonalizations}
              onDeletePersonalization={handleDeletePersonalization}
            />
          )}
        </div>
      </div>
    </div >
  );
});

export default SegmentationPage;